import "./application.css";

import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import ReactDOM from "react-dom/client";
import Select, { ActionMeta, OnChangeValue } from "react-select";

var themeToggleDarkIcon = document.getElementById("theme-toggle-dark-icon");
var themeToggleLightIcon = document.getElementById("theme-toggle-light-icon");

if (
  localStorage.getItem("color-theme") === "dark" ||
  (!("color-theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches)
) {
  // localStorage.setItem("color-theme", "dark");

  themeToggleLightIcon?.classList.remove("hidden");
} else {
  // localStorage.setItem("color-theme", "light");

  themeToggleDarkIcon?.classList.remove("hidden");
}

var themeToggleBtn = document.getElementById("theme-toggle");

themeToggleBtn?.addEventListener("click", function () {
  themeToggleDarkIcon?.classList.toggle("hidden");
  themeToggleLightIcon?.classList.toggle("hidden");

  if (localStorage.getItem("color-theme")) {
    if (localStorage.getItem("color-theme") === "light") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    }
  } else {
    if (document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    }
  }
});

interface User {
  id: number;
  name: string;
}

interface UserSelectData {
  value: number;
  label: string;
}

const TodorDev = ({ date, url, sessionClientsUrl }) => {
  const [options, setOptions] = useState<UserSelectData[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<UserSelectData[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(date));

  const onClick = () => {
    if (disabled) {
      return;
    }

    setDisabled(true);

    axios
      .post(
        sessionClientsUrl,
        { users: selectedOptions, date: selectedDate },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": (
              document.querySelector(
                "meta[name='csrf-token']"
              ) as HTMLMetaElement
            ).content,
          },
        }
      )
      .then((res) => {
        window.location.href = "/";
      });
  };

  const onChange = (
    newValue: OnChangeValue<UserSelectData, true>,
    actionMeta: ActionMeta<UserSelectData>
  ) => {
    switch (actionMeta.action) {
      case "select-option":
      case "remove-value":
      case "pop-value":
      case "clear":
    }

    setSelectedOptions(newValue);
  };

  useEffect(() => {
    const getData = async () => {
      let arr: UserSelectData[] = [];

      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          let result = res.data;

          result.all.map((user: User) => {
            arr.push({ value: user.id, label: user.name });
          });
          setOptions(arr);
          setSelectedOptions(
            result.selected.map((user: User) => {
              return { value: user.id, label: user.name };
            })
          );
        });
    };
    getData();
  }, []);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      width: 500,
      minWidth: "100%",
    }),
  };

  return (
    <>
      <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-2 md:flex-row">
        <DateTimePicker
          format="dd MMMM HH:mm"
          value={selectedDate}
          locale={"bg-BG"}
          className={"min-w-[200px] min-h-fit"}
          onChange={(item) => {
            if (item != null) {
              setSelectedDate(item);
            }
          }}
        />

        <Select
          value={selectedOptions}
          controlShouldRenderValue={false}
          isMulti
          name="users"
          styles={customStyles}
          classNamePrefix="select"
          placeholder="Избери посетители"
          onChange={onChange}
          options={options}
        />
      </div>

      {selectedOptions.length > 0 && (
        <>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-5">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3" scope="col">
                  #
                </th>
                <th className="px-6 py-3" scope="col">
                  Име
                </th>
                <th className="px-6 py-3" scope="col">
                  <span className="sr-only"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedOptions.map((option, index) => (
                <tr
                  key={option.value}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4 font-semibold">{index + 1}</td>
                  <td className="px-6 py-4 font-semibold">{option.label}</td>
                  <td className="flex justify-end	mr-5 mt-2">
                    <div
                      className="cursor-pointer focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-fit"
                      onClick={() => {
                        setSelectedOptions(
                          selectedOptions.filter(
                            (item) => item.value !== option.value
                          )
                        );
                      }}
                    >
                      Премахни
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            disabled={disabled}
            className="w-24 mt-5 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={onClick}
          >
            Запази
          </button>
        </>
      )}
    </>
  );
};

var confirmationButtons = document.querySelectorAll(".confirm");

confirmationButtons.forEach(function (button) {
  button.addEventListener("click", function (e) {
    var confirmation = confirm("Are you sure you want to proceed?");

    if (!confirmation) {
      e.preventDefault();
    }
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const root = document.getElementById("root");

  if (root !== null) {
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <TodorDev
        date={root.dataset.date}
        url={root.dataset.url}
        sessionClientsUrl={root.dataset.sessionClientsUrl}
      />
    );
  }
});
